import { FaAws } from "react-icons/fa6";
import { FaPython } from "react-icons/fa";
import {FaServer} from 'react-icons/fa'
/* import {AiFillAppstore} from 'react-icons/ai'    */


const data = [
    {
        id: 1, icon: <FaPython />, title: 'Unlock Web Insights with Expert Scraping', desc: "Say goodbye to tedious manual scraping. I automate the process of extracting the precise data you need, in the format you want, faster than you ever thought possible." 

    },
    {
        id: 2, icon: <FaAws />, title: 'Unleash Cloud Power with AWS', desc: "Level up your infrastructure with the ultimate cloud toolbox. AWS offers a vast array of services, from storage and compute to AI and analytics, to empower your business with agility, scalability, and innovation. No need for hefty upfront costs – pay only for what you use."
    },
    {
        id: 3, icon: <FaServer/>, title: 'Backend Development', desc: "The security of your business/product is taken seriously right from the start of the project. I will make sure your website/app is secure from attacks."
    },
/*    {
        id: 4, icon: <AiFillAppstore/>, title: 'App Development', desc: 'Unlike other developers in the market, I will building an app that runs on both IOS and Android devices without any extra cost to you.'
    }   */
]


export default data