import AboutImage from '../../assets/amias.jpeg'
import CV from '../../assets/cv.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Image" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Me</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                Building projects my clients love have always been my passion. Being in the software development industry for over 2 years, I'm always motivated to do more!
                </p>
                <p>
                Hi, my name is Amias Yaska. I'm a software developer and AWS Certified Cloud Practitioner. My top priority is to provide you with any data that is publicly available on the internet. Get in touch with me today with the details of your project let's get started! 
                </p>
                {/* <a href={CV} download className='btn primary'>Download CV <HiDownload/></a> */}
                {/* <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="ef563702-24c5-4b36-bd56-49ea22a35481" data-share-badge-host="https://www.credly.com"></div><script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script> */}
                {/* <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="ef563702-24c5-4b36-bd56-49ea22a35481" data-share-badge-host="https://www.credly.com"></div><script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script> */}
            </div>
        </div>
    </section>
  )
}

export default About